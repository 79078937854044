import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isAuth:false,
        theUserID:null,
        theUserName:null,
        theUserEmail:null,
        theactivePlan:null,
        theactivePrice:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
        isLoggedIn = async () => {
            let loginID = JSON.parse(localStorage.getItem('loginID'));
            let loginName = JSON.parse(localStorage.getItem('loginName'));
            let loginEmail = JSON.parse(localStorage.getItem('loginEmail'));
            if (localStorage.getItem('loginID')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUserID:loginID,
                    theUserName:loginName,
                    theUserEmail:loginEmail,
                });
            }
        }
        issetpay = async () => {
            let activePlan = JSON.parse(localStorage.getItem('activePlan'));
            let activePrice = JSON.parse(localStorage.getItem('activePrice'));
            if (localStorage.getItem('activePlan')) {
                this.setState({
                    ...this.state,
                    theactivePlan:activePlan,
                    theactivePrice:activePrice,
                });
            }
        }
        logoutUser = () => {
            localStorage.removeItem('loginID');
            localStorage.removeItem('loginName');
            localStorage.removeItem('loginEmail');
            localStorage.removeItem('activePlan');
            localStorage.removeItem('activePrice');
            this.setState({
                ...this.state,
                isAuth:false,
                theUserID:null,
                theUserName:null,
                theUserEmail:null,
                theactivePlan:null,
                theactivePrice:null,
            })
            window.location.href = '/LoginSection';
        }
        RegisterUser = async (userInfo,recaptcha_response) => {
            const registerqqq = await Axios.post('register.php',{
                user_email:userInfo.email,
                user_name:userInfo.username,
                password:userInfo.password,
                subscribe:userInfo.subscribe,
                recaptcha_response:recaptcha_response,
            });
            return registerqqq.data;
        }
        LoginUser = async (user) => {
            const LoginUserqqq = await Axios.post('login.php',{
                email:user.email,
                password:user.password,
            });
            return LoginUserqqq.data;
        }
   
    plansall = async () => {
        const plansall = await Axios.get('price.php');
        return plansall.data;
    }
   
    AudioBooksaddall = async (books,purchase,purchagelength,theUserid,plans,Amounts) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('ages',books.ages);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        formData.append('plans',plans);
        formData.append('Amounts',Amounts);
        const AudioBooksaddallqqq = await Axios.post('AudioBooksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBooksaddallqqq.data;
    }
    AudioBookseditall = async (books,purchase,purchagelength,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('ages',books.ages);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('bookids',bookids);
        const AudioBookseditallqqq = await Axios.post('AudioBooksedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBookseditallqqq.data;
    }
    
    audiopreviewall = async (ids) => {
        const audiopreviewallqqq = await Axios.post('audiopreview.php',{
            bookids:ids,
        });
        return audiopreviewallqqq.data;
    }
   
    audioreceiptall = async (ids) => {
        const audioreceiptallqqq = await Axios.post('audioreceipt.php',{
            booksids:ids,
        });
        return audioreceiptallqqq.data;
    }
    
    addnewsletters = async (user) => {
        const addnewsletterssss = await Axios.post('addnewsletters.php',{
            email:user.email,
        });
        return addnewsletterssss.data;
    }
    userlistall = async (ids) => {
        const userlistallqqq = await Axios.post('authorprofile.php',{
            userids:ids,
        });
        return userlistallqqq.data;
    }
    editprofilsssall = async (books,purchase,purchagelength,userids) => {
        const formData = new FormData();
        formData.append('name',books.name);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('userids',userids);
        const editprofilsssall = await Axios.post('editprofile.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editprofilsssall.data;
    }
    userlistlimit = async (ids) => {
        const userlistlimitqqq = await Axios.post('limitprofile.php',{
            userids:ids,
        });
        return userlistlimitqqq.data;
    }
    audioplansall = async (ids) => {
        const audioplansall = await Axios.post('audioplansview.php',{
            bookids:ids,
        });
        return audioplansall.data;
    }
    changedplansall = async (plan,amount,ids) => {
        const changedplansallqqq = await Axios.post('changeplans.php',{
            plan:plan,
            amount:amount,
            bookids:ids,
        });
        return changedplansallqqq.data;
    }
    changedplansupgade = async (plan,amount,ids) => {
        const changedplansupgadeqqq = await Axios.post('changeplansupgade.php',{
            plan:plan,
            amount:amount,
            bookids:ids,
        });
        return changedplansupgadeqqq.data;
    }
    booksectionall = async (ids) => {
        const booksectionallqqq = await Axios.post('booksectionall.php',{
            bookids:ids,
        });
        return booksectionallqqq.data;
    }
    bookpaysmsg = async (payids) => {
        const bookpaysmsgqqq = await Axios.post('stripesuccess.php',{
            payids:payids,
        });
        return bookpaysmsgqqq.data;
    }
    bookpaypalall = async (payids) => {
        const bookpaypalall = await Axios.post('paypalsuccess.php',{
            payids:payids,
        });
        return bookpaypalall.data;
    }
   
    userimgall = async (ids) => {
        const userimgallqqq = await Axios.post('userimgall.php',{
            userids:ids,
        });
        return userimgallqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('forgetpassword.php',{
            email:user.email,
        });
        return loginForget.data;
    }
    ChangePasswordall = async (user,passkey) => {
        const ChangePasswordallsss = await Axios.post('changePasswordall.php',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return ChangePasswordallsss.data;
    }
    Latesthomesall = async () => {
        const Latesthomesallsss = await Axios.get('Latesthomesall.php');
        return Latesthomesallsss.data;
    }
    Impressivehomesall = async () => {
        const Impressivehomesallsss = await Axios.get('Impressivehomes.php');
        return Impressivehomesallsss.data;
    }
    featuredhomesall = async () => {
        const featuredhomesallsss = await Axios.get('featuredhomes.php');
        return featuredhomesallsss.data;
    }
    audiohomesall = async () => {
        const audiohomesallqqq = await Axios.get('audiohomes.php');
        return audiohomesallqqq.data;
    }
    storageuserall = async (userids) => {
        const storageuserallsss = await Axios.post('storageuser.php',{
            userids:userids
        });
        return storageuserallsss.data;
    }
    Latestpremiumsall = async (userids) => {
        const Latestpremiumsallsss = await Axios.post('latestpremiums.php',{
            userids:userids
        });
        return Latestpremiumsallsss.data;
    }
    bookpreviewall = async (ids) => {
        const bookpreviewall = await Axios.post('bookpreview.php',{
            bookids:ids,
        });
        return bookpreviewall.data;
    }
    Featuredlist  = async (pageNumber) => {
        const Featuredlistqqq = await Axios.get(`Featuredall.php?page=${pageNumber}`);
        return Featuredlistqqq.data;
    }
    freelist  = async (pageNumber) => {
        const freelistqqq = await Axios.get(`freelistall.php?page=${pageNumber}`);
        return freelistqqq.data;
    }
    premiumlist  = async (pageNumber) => {
        const premiumlistqqq = await Axios.get(`premiumlistall.php?page=${pageNumber}`);
        return premiumlistqqq.data;
    }
    standardlist  = async (pageNumber) => {
        const standardlistqqq = await Axios.get(`standardlistall.php?page=${pageNumber}`);
        return standardlistqqq.data;
    }
    AudioSamplelist  = async (pageNumber) => {
        const AudioSamplelist = await Axios.get(`audiosampleall.php?page=${pageNumber}`);
        return AudioSamplelist.data;
    }
    recentpremuiumall  = async () => {
        const recentpremuiumallqqq = await Axios.get(`recentpremuiumall.php`);
        return recentpremuiumallqqq.data;
    }
    categorylistpreviewall  = async (pageNumber,titles) => {
        const categorylistpreviewallqqq = await Axios.get(`categorylist.php?page=${pageNumber}&categorybook=${titles}`);
        return categorylistpreviewallqqq.data;
    }
    Authorslist  = async (pageNumber) => {
        const Authorslist = await Axios.get(`Authorsall.php?page=${pageNumber}`);
        return Authorslist.data;
    }
    freelistapproval  = async (pageNumber) => {
        const freelistapprovalqqq = await Axios.get(`freelistapprovalall.php?page=${pageNumber}`);
        return freelistapprovalqqq.data;
    }
    userbookspreviewall = async (userids) => {
        const userbookspreviewallsss = await Axios.post('userbooks.php',{
            userids:userids,
        });
        return userbookspreviewallsss.data;
    }
    approvalfreelist = async (bookids) => {
        const approvalfreelistqqq = await Axios.post('approvalfreeall.php',{
            ids:bookids,
        });
        return approvalfreelistqqq.data;
    }
    deletefreelist = async (bookids) => {
        const deletefreelistqqq = await Axios.post('deletefreeall.php',{
            ids:bookids,
        });
        return deletefreelistqqq.data;
    }
    agelistthree  = async (pageNumber) => {
        const agelistthreeqqq = await Axios.get(`agelistthreeall.php?page=${pageNumber}`);
        return agelistthreeqqq.data;
    }
    agelistfour  = async (pageNumber) => {
        const agelistfourqqq = await Axios.get(`agelistfourall.php?page=${pageNumber}`);
        return agelistfourqqq.data;
    }
    agelisttweleve  = async (pageNumber) => {
        const agelisttweleveqqq = await Axios.get(`agelisttweleveall.php?page=${pageNumber}`);
        return agelisttweleveqqq.data;
    }
    agelisttweleveplus  = async (pageNumber) => {
        const agelisttweleveplusqqq = await Axios.get(`agelisttweleveplusall.php?page=${pageNumber}`);
        return agelisttweleveplusqqq.data;
    }
    agefirstreaders  = async (pageNumber) => {
        const agefirstreadersqqq = await Axios.get(`agefirstreadersall.php?page=${pageNumber}`);
        return agefirstreadersqqq.data;
    }
    agesecondreaders  = async (pageNumber) => {
        const agesecondreadersqqq = await Axios.get(`agesecondreadersall.php?page=${pageNumber}`);
        return agesecondreadersqqq.data;
    }
    
  
   
  
  
  


    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            issetpay:this.issetpay,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            plansall:this.plansall,
            AudioBooksaddall:this.AudioBooksaddall,
            AudioBookseditall:this.AudioBookseditall,
            audiopreviewall:this.audiopreviewall,
            addnewsletters:this.addnewsletters,
            userlistall:this.userlistall,
            editprofilsssall:this.editprofilsssall,
            userlistlimit:this.userlistlimit,
            audioplansall:this.audioplansall,
            changedplansall:this.changedplansall,
            changedplansupgade:this.changedplansupgade,
            booksectionall:this.booksectionall,
            bookpaysmsg:this.bookpaysmsg,
            bookpaypalall:this.bookpaypalall,
            audioreceiptall:this.audioreceiptall,
            userimgall:this.userimgall,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            Latesthomesall:this.Latesthomesall,
            Impressivehomesall:this.Impressivehomesall,
            featuredhomesall:this.featuredhomesall,
            storageuserall:this.storageuserall,
            Latestpremiumsall:this.Latestpremiumsall,
            bookpreviewall:this.bookpreviewall,
            Featuredlist:this.Featuredlist,
            freelist:this.freelist,
            premiumlist:this.premiumlist,
            recentpremuiumall:this.recentpremuiumall,
            categorylistpreviewall:this.categorylistpreviewall,
            audiohomesall:this.audiohomesall,
            AudioSamplelist:this.AudioSamplelist,
            Authorslist:this.Authorslist,
            userbookspreviewall:this.userbookspreviewall,
            freelistapproval:this.freelistapproval,
            approvalfreelist:this.approvalfreelist,
            deletefreelist:this.deletefreelist,
            standardlist:this.standardlist,
            agelistthree:this.agelistthree,
            agelistfour:this.agelistfour,
            agelisttweleve:this.agelisttweleve,
            agelisttweleveplus:this.agelisttweleveplus,
            agefirstreaders:this.agefirstreaders,
            agesecondreaders:this.agesecondreaders,
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;