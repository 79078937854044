import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router,Redirect,Switch,Route} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
//import Sticky from './Sticky';
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contactus = lazy(() => import('./Contactus'));
const BooksPreview = lazy(() => import('./BooksPreview'));
const PremiumBooks = lazy(() => import('./PremiumBooks'));
const FeaturedBooks = lazy(() => import('./FeaturedBooks'));
const StandardBooks = lazy(() => import('./StandardBooks'));
const FreeBooks = lazy(() => import('./FreeBooks'));
const Authors = lazy(() => import('./Authors'));
const Pricing = lazy(() => import('./Pricing'));
const AuthorPage = lazy(() => import('./AuthorPage'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));
const LoginSection = lazy(() => import('./login/LoginSection'));
const Logout = lazy(() => import('./login/Logout'));
const AccountSection = lazy(() => import('./account/AccountSection'));
const EditProfile = lazy(() => import('./account/EditProfile'));
const AdminSection = lazy(() => import('./account/AdminSection'));
const AddBook = lazy(() => import('./account/AddBook'));
const EditBookSection = lazy(() => import('./account/EditBookSection'));
const EditBookSectionPay = lazy(() => import('./account/EditBookSectionPay'));
const EditBook = lazy(() => import('./account/EditBook'));
const PaymentPage = lazy(() => import('./account/payment/PaymentPage'));
const StripeSuccessaudio = lazy(() => import('./account/payment/StripeSuccessaudio'));
const StripeCancelaudio = lazy(() => import('./account/payment/StripeCancelaudio'));
const StripeCancelupgradebooks = lazy(() => import('./account/payment/StripeCancelupgradebooks'));
const PaypalSuccessaudio = lazy(() => import('./account/payment/PaypalSuccessaudio'));
const PaypalCancelaudio = lazy(() => import('./account/payment/PaypalCancelaudio'));
const AudioCancelled = lazy(() => import('./account/payment/AudioCancelled'));
const PaymentPageUpgrade = lazy(() => import('./account/payment/PaymentPageUpgrade'));
const AudioRecepits = lazy(() => import('./account/payment/AudioRecepits'));
const NotFound = lazy(() => import("./NotFound"));
const Demo = lazy(() => import("./Demo"));
const AgesThree = lazy(() => import("./AgesThree"));
const AgesFour = lazy(() => import("./AgesFour"));
const AgesTwelve = lazy(() => import("./AgesTwelve"));
const AgesTwelvePlus = lazy(() => import("./AgesTwelvePlus"));
const FirstReaders = lazy(() => import("./FirstReaders"));
const SecondReaders = lazy(() => import("./SecondReaders"));

function Dashboard() {
  return (<>   
    <Router basename="/">
      <Suspense fallback={<div className="divLoaderwww">
        <div id="preloader-wrapper"><div class="preloader-container"><div class="cssload-loader"></div></div></div>
      </div>}>
        <ToastContainer />
        <Switch>
         <Route exact path="/" component={Home}/>
         <Route exact path="/AgesThree" component={AgesThree}/>
         <Route exact path="/AgesFour" component={AgesFour}/>
         <Route exact path="/AgesTwelve" component={AgesTwelve}/>
         <Route exact path="/AgesTwelvePlus" component={AgesTwelvePlus}/>
         <Route exact path="/FirstReaders" component={FirstReaders}/>
         <Route exact path="/SecondReaders" component={SecondReaders}/>
         <Route exact path="/LoginSection" component={LoginSection}/>
         <Route exact path="/ForgetPassword" component={ForgetPassword}/>
         <Route exact path="/PremiumBooks" component={PremiumBooks}/>
         <Route exact path="/FeaturedBooks" component={FeaturedBooks}/>
         <Route exact path="/StandardBooks" component={StandardBooks}/>
         <Route exact path="/FreeBooks" component={FreeBooks}/>
         <Route exact path="/Authors" component={Authors}/>
         <Route exact path="/Pricing" component={Pricing}/>
         <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
         <Route exact path="/AccountSection" component={AccountSection}/>
         <Route exact path="/EditProfile" component={EditProfile}/>
         <Route exact path="/About" component={About}/>
         <Route exact path="/AddBook" component={AddBook}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/EditBookSection" component={EditBookSection}/>
         <Route exact path="/EditBookSectionPay" component={EditBookSectionPay}/>
         <Route exact path="/EditBook/:Addpreview" component={EditBook}/>
         <Route exact path="/AuthorPage/:Addpreview" component={AuthorPage}/>
         <Route exact path="/BooksPreview/:Addpreview/:Addtitle" component={BooksPreview}/>
         <Route exact path="/PaymentPage/:Addpreview" component={PaymentPage}/>
         <Route exact path="/PaymentPageUpgrade/:Addpreview" component={PaymentPageUpgrade}/>
         <Route exact path="/StripeSuccessaudio/:Addpreview" component={StripeSuccessaudio}/>
         <Route exact path="/StripeCancelaudio/:Addpreview" component={StripeCancelaudio}/>
         <Route exact path="/StripeCancelupgradebooks/:Addpreview" component={StripeCancelupgradebooks}/>
         <Route exact path="/PaypalSuccessaudio/:Addpreview" component={PaypalSuccessaudio}/>
         <Route exact path="/PaypalCancelaudio/:Addpreview" component={PaypalCancelaudio}/>
         <Route exact path="/AudioCancelled" component={AudioCancelled}/>
         <Route exact path="/AudioRecepits/:Addpreview" component={AudioRecepits}/>
         <Route exact path="/AdminSection" component={AdminSection}/>
         <Route exact path="/Contactus" component={Contactus}/>
          

         <Route exact path="/404" component={NotFound} />
         <Route exact path="/Demo" component={Demo} />
         <Redirect to="/404" />

        </Switch>
      </Suspense>
      {/* <Sticky /> */}
    </Router>
    
    </>
  );
}
export default Dashboard;
